import Vue from 'vue'

import hotspotsMixin from '../mixins/hotspots'
import dragDetectorMixin from '../mixins/dragDetector'
import { position } from '../util/event'

export default Vue.extend({
  name: 'XpImg',
  mixins: [hotspotsMixin, dragDetectorMixin],
  props: {
    src: String,
    alt: String,
    width: Number,
    height: Number,
    placeholderSrc: String
  },
  data () {
    return {
      currentSrc: '',
      image: null,
      isLoading: !!this.src,
      isComplete: false, // Preview wurde durch Vollversion ersetzt. Alles fertig.
      hasError: false,
      naturalRatio: undefined,
      svgImageBoundingClientRect: null,
      xpImageBoundingClientRect: null,
      imageID: 'i' + Math.random().toString(16).slice(2),
      iconSize: this.$xp.content.pageData.IconSize ? this.$xp.content.pageData.IconSize : 100 // Größe ImageMap-Icons in %
    }
  },
  watch: {
    src () {
      this.__load()
    }
  },
  computed: {
    url () {
      return this.currentSrc || this.placeholderSrc || undefined
    },
    style () {
      return {
        width: Number(this.width),
        height: Number(this.height),
        backgroundImage: 'url(' + this.url + ')'
      }
    }
  },
  created () {
    this.screenUnwatcher = this.$watch(() => [
      // this.$xp.screen.orientation,
      this.$xp.screen.width,
      this.$xp.screen.height
    ].join(), async () => {
      await this.$nextTick()
      setTimeout(() => {
        this.__updateImageBoundingRect()
      }, 0)
    })
  },
  methods: {
    __onLoad (img) {
      this.isLoading = false
      this.hasError = false
      this.__computeRatio(img)
      this.__updateSrc()
      this.__updateImageBoundingRect()
      this.$emit('load', this.currentSrc)
    },
    __updateImageBoundingRect () {
      this.xpImageBoundingClientRect = this.$el.getBoundingClientRect().toJSON()
      this.svgImageBoundingClientRect = this.$el.querySelector('svg image') ? this.$el.querySelector('svg image').getBoundingClientRect().toJSON() : null
    },
    __onError (err) {
      console.warn(err)
      this.isLoading = false
      this.hasError = true
      this.currentSrc = ''
      this.$emit('error', err)
    },
    __updateSrc () {
      if (this.image !== undefined && this.isLoading === false) {
        const src = this.image.currentSrc || this.image.src
        if (this.currentSrc !== src) {
          this.currentSrc = src
          setTimeout(() => { // Timeout, da sonst Bild einen winzigen Moment in Thumbnail-Size zu sehen ist
            this.isComplete = true
          }, 50)
        }
      }
    },
    __load () {
      this.hasError = false
      if (!this.src) {
        this.isLoading = false
        this.image = undefined
        this.currentSrc = ''
        return
      }
      this.isLoading = true
      const img = new Image()
      this.image = img
      this.$xp.http.getFileUrl(this.src)
        .then(data => {
          if (this.destroyed === true) {
            return
          }
          img.src = data
          // if we are still rendering same image
          if (this.image === img) {
            this.__onLoad(img)
          }
        })
        .catch(error => {
          // if we are still rendering same image
          if (this.image === img && this.destroyed !== true) {
            this.__onError(error)
          }
        })
      Object.assign(img, {
        height: this.height,
        width: this.width
      })
    },
    __hotspotClickHandler (event) {
      const coords = position(event)
      const bb = event.target.getBoundingClientRect()
      this.checkForHotspot([(coords.left - bb.left) / bb.width * 100, (coords.top - bb.top) / bb.height * 100])
    },
    __computeRatio (img) {
      const { naturalHeight, naturalWidth } = img
      if (naturalHeight || naturalWidth) {
        this.naturalRatio = naturalHeight === 0
          ? 1
          : naturalWidth / naturalHeight
      } else {
        this.ratioTimer = setTimeout(() => {
          if (this.image === img && this.destroyed !== true) {
            this.__computeRatio(img)
          }
        }, 100)
      }
    }
  },
  render (h) {
    if (this.width && this.height && this.hotspots.length) {
      return h('div', {
        staticClass: 'xp-img',
        style: this.style
      }, [
        h('div',
          {
            staticClass: 'xp-img__background-cover'
          }
        ),
        h('svg', {
          attrs: {
            width: '100%',
            height: '100%',
            viewBox: `0, 0, ${this.width}, ${this.height}`,
            version: '1.1',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            role: 'img',
            'aria-labelledby': `binarylabel${this.imageID}`
          }
        }, [
          h('title', {
            attrs: {
              id: `binarylabel${this.imageID}`
            }
          },
          this.alt
          ),
          h('image', {
            attrs: {
              width: this.width,
              height: this.height,
              'xlink:href': this.url,
              'data-binary-src': this.src
            }
          }),
          ...this.hotspots.map(hotspot => {
            const hotspotShapAttrs = (() => {
              switch (hotspot.shape) {
                case 'rect':
                  return {
                    width: (hotspot.br.x - hotspot.tl.x) * this.width / 100,
                    height: (hotspot.br.y - hotspot.tl.y) * this.height / 100,
                    x: hotspot.tl.x * this.width / 100,
                    y: hotspot.tl.y * this.height / 100
                  }
                case 'circle':
                  return {
                    cx: hotspot.center.x * this.width / 100,
                    cy: hotspot.center.y * this.height / 100,
                    r: hotspot.radius * this.width / 100
                  }
                case 'polygon':
                case 'poly': {
                  const coords = []
                  hotspot.points.map((point) => {
                    coords.push(point.x * this.width / 100 + ',' + point.y * this.height / 100)
                  })
                  return {
                    points: coords.join(' ')
                  }
                }
              }
            })()
            return h(hotspot.shape === 'poly' ? 'polygon' : hotspot.shape, {
              staticClass: 'xp-img__svg-path',
              on: {
                touchend: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } },
                mouseup: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } }
              },
              attrs: Object.assign(
                {
                  role: 'link',
                  'aria-label': (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt,
                  title: (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt,
                  tabindex: 0,
                  fill: 'transparent'
                },
                hotspotShapAttrs
              )
            }, [])
          })
        ]),
        ...(!this.isLoading ? this.htmlIconHotspots.map(hotspot => h(
          'span',
          {
            class: `xp xp--large xp-img__hotspot-icon xp-img__hotspot-icon--${hotspot.icon}`,
            style: {
              top: ((((this.xpImageBoundingClientRect.height - this.svgImageBoundingClientRect.height) / 2) + (hotspot.centerYPercent * this.svgImageBoundingClientRect.height)) / this.xpImageBoundingClientRect.height) * 100 + '%',
              left: ((((this.xpImageBoundingClientRect.width - this.svgImageBoundingClientRect.width) / 2) + (hotspot.centerXPercent * this.svgImageBoundingClientRect.width)) / this.xpImageBoundingClientRect.width) * 100 + '%',
              borderRadius: (0.333334 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              borderWidth: (2 / window.devicePixelRatio * this.iconSize / 100) + 'px',
              height: (4 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              margin: (-2 / window.devicePixelRatio * this.iconSize / 100) + 'rem' + ' 0 0 -' + (3 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              width: (6 / window.devicePixelRatio * this.iconSize / 100) + 'rem',
              fontSize: (hotspot.icon === 'display' ? (2.75 / window.devicePixelRatio * this.iconSize / 100) : (5 / window.devicePixelRatio * this.iconSize / 100)) + 'rem',
              paddingTop: hotspot.icon === 'display' ? (0.35 / window.devicePixelRatio * this.iconSize / 100) + 'rem' : '0'
            },
            attrs: {
              href: hotspot.href,
              title: (hotspot.alt === '' || hotspot.alt === undefined) ? '' : hotspot.alt
            },
            on: {
              touchend: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } },
              mouseup: (e) => { if (!this.isDragged) { if (e.cancelable === true) e.preventDefault(); this.hotspotClicked(hotspot) } }
            }
          }, hotspot.icon === 'display' && hotspot.icontext ? hotspot.icontext : ''
        )) : [])
      ])
    }
    return h('div', {
      class: 'xp-img',
      style: this.style
    }, [
      h('div',
        {
          staticClass: 'xp-img__background-cover'
        }
      ),
      h('img', {
        staticClass: 'xp-img__image',
        class: !this.isComplete ? 'preview' : '',
        attrs: { src: this.url, 'data-binary-src': this.src, alt: (this.alt === '' || this.alt === undefined) ? this.$xp.content.pageData.Title : this.alt },
        style: {
          filter: this.isLoading ? 'blur(10px)' : null
        },
        on: this.hotspots !== undefined && this.hotspots.length > 0
          ? {
            ...this.$listeners,
            mouseup: this.__hotspotClickHandler,
            touchend: this.__hotspotClickHandler
          }
          : this.$listeners // , 'touchend': this.__getHotspotLister(), 'mouseup': this.__getHotspotLister()}
      })
    ])
  },
  beforeMount () {
    if (this.placeholderSrc !== undefined) {
      const img = new Image()
      img.src = this.placeholderSrc
      img.onload = () => {
        this.__computeRatio(img)
      }
    }
    this.isLoading === true && this.__load()
  },

  beforeDestroy () {
    this.destroyed = true
    this.screenUnwatcher()
    this.isLoading && this.$xp.http.cancelRequest(this.src)
    this.image.src = '' // to abort loading
  }
})
