import throttle from '../util/throttle'
import { position } from '../util/event'

const dragDetector = {
  date () {
    return {
      isDragged: false
    }
  },
  mounted () {
    this.onStart = throttle((e) => {
      this.isDown = true
      this.startTime = Date.now()
      this.isDragged = false
      this.startPosition = position(e)
    })
    this.onEnd = throttle(() => {
      this.isDown = false
    })
    this.onMove = throttle((e) => {
      if (this.isDown) {
        const currentPosition = position(e)
        const dX = Math.abs(this.startPosition.top - currentPosition.top)
        const dY = Math.abs(this.startPosition.left - currentPosition.left)
        if ((dX >= 3 || dY >= 3) || Date.now() - this.startTime > 800) {
          this.isDragged = true
        }
      }
    })
    document.addEventListener('touchstart', this.onStart)
    document.addEventListener('mousedown', this.onStart)
    document.addEventListener('touchmove', this.onMove)
    document.addEventListener('mousemove', this.onMove)
    document.addEventListener('touchend', this.onEnd)
    document.addEventListener('mouseup', this.onEnd)
  },
  beforeDestroy () {
    document.removeEventListener('touchstart', this.onStart)
    document.removeEventListener('mousedown', this.onStart)
    document.removeEventListener('touchmove', this.onMove)
    document.removeEventListener('mousemove', this.onMove)
    document.removeEventListener('touchend', this.onEnd)
    document.removeEventListener('mouseup', this.onEnd)
  }
}

export default dragDetector
